/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
/*
@import 'bootstrap-icons/font/bootstrap-icons';
*/
@import 'bootstrap';
@import 'bootstrap-icons';

@import 'bootstrap-utilities';


html {
	font-size:12px;
	height: 100vh;
}
body {
	height:100vh;
	overflow:hidden;
}

/* This style comes from the highlightsearch pipe component, but pipes
 * cannot have their own style rules
 */
span.searchresult {
	text-decoration:underline;
	font-weight:bold;
	color:white;
}

.mat-mdc-cell:has(> span.searchresult), .mat-mdc-cell:has(> div > span.searchresult) {
	background-color:mix($success, white, 50%);
}

/* Not sure why this has to be here, but it does */
.mat-mdc-table div.mat-sort-header-content {
	white-space:nowrap;
}
